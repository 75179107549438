import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Contact from "../components/Contact";

const EDUHeroStyle = styled.div`
    width: 100%;
    background: linear-gradient(180deg, #0053A6 0%, #00A0E9 100%);
    display: flex;
    flex-direction: column;
    padding: 50px 20px 0;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid var(--blue);
    .EDUHero-img {
        width: 100%;
        max-width: 1200px;
        margin-bottom: -10px;
        img { width: 100% };
    }
    .EDUHero-text {
        color: white;
        margin-bottom: 40px;
        .title {
            font-size: 40px;
            font-weight: bold;
            span { 
                color: #33E7FF; 
                margin-left: 10px;
            }
        }
        h3 {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 480px) {
        padding: 30px 0 0;
        .EDUHero-img {
            img { display: none; }
            height: 240px;
            margin-bottom: 0;
            background: url("https://mtimg.onemiao.cn/web-images/edu/edu_hero_s.png") center bottom no-repeat;
            background-size: contain;
        }
    }
`

const EDUMapStyle = styled.div`
    width: 100%;
    background: var(--blue) url("https://mtimg.onemiao.cn/web-images/edu/edu_map_bg.svg") center no-repeat;
    background-size: 120% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    .map-img {
        width: 100%;
        max-width: 1240px;
        img { width: 100% };
    }
    @media (max-width: 480px) {
        padding: 20px 10px 0;
        .map-img {
            height: 600px;
            background: url("https://mtimg.onemiao.cn/web-images/edu/edu_map_s.png") center no-repeat;
            background-size: contain;
            img { display: none; }
        }

    }
`

const DeepBlueStyle = styled.div`
    width: 100%;
    background-color: var(--deepBlue);
    padding: 60px 20px;
    h2 {
        color: white;
        font-weight: bold;
        margin-bottom: 40px;
        text-align: center;
    }
    .deep-blue-cards {
        width: 100%;
        max-width: 1160px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        .deep-blue-card {
            width: 50%;
            padding: 10px;
            display: flex;
            justify-content: stretch;
            align-items: stretch;
            .card-content {
                border-radius: 10px;
                background-color: white;
                padding: 20px;
                img { 
                    width: 100%;
                    margin-bottom: 20px;
                }
                .title {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
            }
        }
    }
    @media (max-width: 480px) {
        h2 {
            margin-bottom: 20px;
        }
        .deep-blue-cards {
            .deep-blue-card {
                width: 100%;
                .card-content {
                    padding: 10px;
                    img { margin-bottom: 10px; }
                }
            }
        }
    }
`

const EDUAdvantageStyle = styled.div`
    width: 100%;
    background-color: var(--lightGray);
    padding: 60px 20px 120px;
    h2 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .advantage-list {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        .advantage-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin-bottom: 20px;
            .advantage-item-h {
                width: 100%;
                border-radius: 10px;
                background-color: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                .advantage-graphic {
                    width: 65%;
                    img { width: 100%; }
                }
                .advantage-text { 
                    max-width: 260px; 
                    h3 {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                    p { color: var(--dark); }
                }
            }
            .advantage-item-v {
                width: 100%;
                border-radius: 10px;
                background-color: white;
                padding: 20px;
                img { 
                    width: 100%; 
                    height: 280px;
                    object-fit: contain;
                    margin-bottom: 20px;
                }
                h3 {
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 5px;
                }
                p {
                    color: var(--dark);
                }
                :first-child {
                    width: 44%;
                    margin-right: 20px;
                }
                :last-child {
                    flex: 1;
                }
            }
        }
    }
    @media (max-width: 480px) {
        padding: 40px 10px;
        .advantage-list .advantage-row .advantage-item-h {
            flex-direction: column;
            align-items: flex-start;
            padding: 10px;
            .advantage-text {
                width: 100%;
                max-width: unset;
                margin-bottom: 20px;
                margin-top: 10px;
                h3 { text-align: center; }
            }
            .advantage-graphic {
                width: 100%;
            }
        }
        .advantage-list .advantage-row {
            :nth-child(2) {
                .advantage-item-h .advantage-text {
                    margin-bottom: 10px;
                }
            }
            :last-child {
                flex-direction: column;
                .advantage-item-v {
                    width: 100%;
                    margin-bottom: 20px;
                    img { height: auto; }
                }
            }
        }
    }
`

export default function MiaotongEDU() {
    return <div>
        <EDUHeroStyle>
            <div className="EDUHero-text">
                <div className="title">苗通<span>EDU</span></div>
                <h2>用户宣教及医生学术培训系统</h2>
                <h3>Miao Education System</h3>
                <h2>帮助企业进行合规化<br />用户宣教及医生学术培训</h2>
            </div>
            <div className="EDUHero-img">
                <img src="https://mtimg.onemiao.cn/web-images/edu/edu_hero.png" alt="" />
            </div>
        </EDUHeroStyle>
        <div className="section-base-row">
            <h1>产品核心功能</h1>
            <div className="section-head edu1">
                <div className="section-info">
                    <div className="section-title">创新模式批量触达疫苗消费者</div>
                    <h2>通过公益形式对用户进行科普知识宣教，提高用户对疫苗知识的认知</h2>
                    <h3>苗通基于“公益为先”的原则，通过与多家国家级公益基金会的合作，制作权威的公共卫生科普内容，并整合基层媒体渠道进行内容分发，用潜在用户进行科普宣传。</h3>
                    <div className="function-group">
                        <h2>重点功能</h2>
                        <div className="function-icon-row">
                            <span>渠道管理</span>
                            <span>素材分类</span>
                            <span>设备组管理</span>
                            <span>发布管理</span>
                        </div>
                    </div>
                </div>
                <StaticImage src="https://mtimg.onemiao.cn/web-images/edu/edu_pic1.png" alt="" className="section-head-img" />
            </div>
        </div>
        <div className="section-cards-wrap gray edu1">
            <h2>产品优势</h2>
            <div className="card-group">
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic1.svg" alt="" />
                    </div>
                    <h2>公益是最佳大规模宣教方式</h2>
                    <p>由于医药行业的特殊性，医院等公共卫生服务机构是保健宣教的重要阵地，公益活动是组织大规模宣教活动的最佳方式。</p>
                </div>
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic2.svg" alt="" />
                    </div>
                    <h2>助力公卫宣教渠道提升效果</h2>
                    <p>基层公卫服务机构本身有着科普宣教的工作职责，苗通EDU平台与合作的公卫机构一起，利用信息化工具提高了宣教工作的效率。</p>
                </div>
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic3.svg" alt="" />
                    </div>
                    <h2>创新模式助力科普宣教覆盖率</h2>
                    <p>国家对疾病预防科普宣教非常重视，苗通EDU创新的信息化宣教平台实现线上线下相结合，助力提升健康宣教人群覆盖率。</p>
                </div>
            </div>
        </div>
        <div className="section-base-row">
            <div className="section-head edu2">
                <StaticImage src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic4.png" alt="" className="section-head-img" />
                <div className="section-info">
                    <div className="section-title">多类型、多渠道合规内容展示</div>
                    <h2>完善的全渠道整合，可承载多类型的健康内容宣传教育</h2>
                    <h3>通过在全国重要直辖市、省份一二线城市的大型基层社区医院、妇幼保健院部署的「宣传书架」以及可进行云端发布电子化宣传内容的「物联网宣教屏」，全品类渠道可同时满足各种类型宣教内容的传播。</h3>
                    <div className="function-group">
                        <h2>重点功能</h2>
                        <div className="function-icon-row">
                            <span>素材管理</span>
                            <span>布局管理</span>
                            <span>项目管理</span>
                            <span>实时审计</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <EDUMapStyle>
            <div className="map-img">
                <img src="https://mtimg.onemiao.cn/web-images/edu/edu_map.png" alt="" />
            </div>
        </EDUMapStyle>
        <DeepBlueStyle>
            <h2>产品优势</h2>
            <div className="deep-blue-cards">
                <div className="deep-blue-card">
                    <div className="card-content">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic5.png" alt="" />
                        <div className="title">传统与创新投放相结合</div>
                        <p>由于同时具备部署在线下的宣教工具以及拥有专业资质的线上互联网推广平台，既可以满足传统海报、手册等宣教物资的投放，也可以承接宣传片、动画科普等多种类型宣传素材的在线投放。</p>
                    </div>
                </div>
                <div className="deep-blue-card">
                    <div className="card-content">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic6.png" alt="" />
                        <div className="title">模块化组合灵活投放时间</div>
                        <p>提供多种宣教内容投放形式，支持模块化自由组合适应更多投放需求。完备的项目制投放管理方案，实现个性化方案设置，精细化投放管理。</p>
                    </div>
                </div>
                <div className="deep-blue-card">
                    <div className="card-content">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic7.png" alt="" />
                        <div className="title">可视化投放素材管理后台</div>
                        <p>本产品通过云端发布中心，可远程一键操纵，流程更简洁、优化了传统的素材制作和发布流程。所有素材通过专属云空间在线储存，并支持自定义分类管理。</p>
                    </div>
                </div>
                <div className="deep-blue-card">
                    <div className="card-content">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic8.png" alt="" />
                        <div className="title">可实时跟踪查看的数据看板</div>
                        <p>根据项目需求定制化开放审计后台，可随时随地查看项目进度，了解每日宣教目标完成情况、宣教素材播放情况等数据，并支持在线预览宣教设备播放实景，轻松把控投放效果。</p>
                    </div>
                </div>
            </div>
        </DeepBlueStyle>
        <div className="section-base-row">
            <div className="section-head edu3">
                <div className="section-info">
                    <div className="section-title">线上及线下融合学术培训执行</div>
                    <h2>通过线上学习工具及线下组织能力，<br />切实提升医护人员专业知识与能力</h2>
                    <h3>作为一站式内容学习与效果反馈平台，拥有完善的课程学习、课程问答、考试题测、证书发放、数据统计等一系列功能。同时拥有5000+线下基层医生及护士的组织招募能力，可根据培训需求切实完成医师的组织与培训目标。</h3>
                    <div className="function-group">
                        <h2>重点功能</h2>
                        <div className="function-icon-row">
                            <span>科室会记录</span>
                            <span>培训管理</span>
                            <span>考核管理</span>
                            <span>数据统计</span>
                        </div>
                    </div>
                </div>
                <StaticImage src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic9.png" alt="" className="section-head-img"  />
            </div>
        </div>
        <EDUAdvantageStyle>
            <h2>产品优势</h2>
            <div className="advantage-list">
                <div className="advantage-row">
                    <div className="advantage-item-h">
                        <div className="advantage-text">
                            <h3>科室会培训全流程记录</h3>
                            <p>根据产品及行业属性，提供贴合业务需求的培训记录模板，可涵盖科室会培训的各个流程，进行全维度记录，为业务人员提供“最实用”的培训管理及复盘工具。</p>
                        </div>
                        <div className="advantage-graphic">
                            <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic10.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="advantage-row">
                    <div className="advantage-item-h">
                        <div className="advantage-graphic">
                            <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic11.png" alt="" />
                        </div>
                        <div className="advantage-text">
                            <h3>培训内容合规传递留痕</h3>
                            <p>借助苗通体系内各业务线，从公益活动策划到培训内容制作、从线上发布到线下维护，从定制管理到数据分析，提供全流程、全闭环的服务。培训全流程管理均可通过本产品实现，轻松做到过程留存，为内容合规传递提供保障。</p>
                        </div>
                    </div>
                </div>
                <div className="advantage-row">
                    <div className="advantage-item-v">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic12.png" alt="" />
                        <h3>线上远程的多形式培训</h3>
                        <p>拥有讲师直播课、录播培训课、互动小班课、互动大班课等多种培训形式，满足多种场景需求。可通过在线一站式系统配置，远程实现多种形式培训的落地执行。</p>
                    </div>
                    <div className="advantage-item-v">
                        <img src="https://mtimg.onemiao.cn/web-images/edu/edu_graphic13.png" alt="" />
                        <h3>可实时跟踪查看数据看板</h3>
                        <p>本产品具有完备的线上信息收集与审核功能、数据统计功能，通过人性化的信息收集方式与资质审核机制，可精准触达培训用户；学习数据根据培训进程在系统中同步更新，可实时查看数据看板。同时提供详实的数据分析服务，为企业的后续发展与战略部署提供参考。</p>
                    </div>
                </div>
            </div>
        </EDUAdvantageStyle>
        <Contact product={'EDU'} />
    </div>
}